import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './LiveChatLink.module.scss'

import { ReactComponent as LiveIcon } from 'i/icons/live.svg'
import { ReactComponent as CloseIcon } from 'i/icons/close.svg'
import { callApi } from 'api'
import { Spinner } from 'components/Spinner'
import { useFetch } from 'hooks'
import { PROMISE_STATES } from 'utils/constants'

function LiveChatLink({ user }) {
  const [isOpen, setIsOpen] = useState(false)
  const { loading, error, data, fetchData } = useFetch(() => callApi.huddleTalk(), {
    auto: false,
  })

  const handleClick = () => {
    setIsOpen(!isOpen)

    if (!isOpen) {
      fetchData()
    }
  }

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.wrapOpen]: isOpen,
      })}
    >
      <div className={styles.tooltip}>
        <button className={styles.tooltipClose} type="button" onClick={handleClick}>
          <CloseIcon className={styles.tooltipCloseIcon} />
        </button>
        <div className={styles.tooltipText}>
          Paste this link where needed so attendees can join live. They will be asked
          there names to talk
        </div>
        <div className={styles.tooltipLinkWrap}>
          {loading === PROMISE_STATES.pending && (
            <div class={styles.spinner}>
              <Spinner />
            </div>
          )}
          {loading === PROMISE_STATES.rejected && (
            <div className={styles.error}>{error}</div>
          )}
          {loading === PROMISE_STATES.fulfilled && data?.result?.join_call_url && (
            <a
              href={`https://www.talkpro.com/${user.channel_name}/room`}
              className={styles.tooltipLink}
              target="_blank"
              rel="noreferrer"
            >
              https://www.talkpro.com/{user.channel_name}/room
            </a>
          )}
        </div>
      </div>
      <button type="button" className={styles.button} onClick={handleClick}>
        <div className={styles.buttonIcon}>
          <LiveIcon className={styles.buttonIconImg} />
        </div>
      </button>
    </div>
  )
}

export { LiveChatLink }
