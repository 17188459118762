import React from 'react'
import { connect } from 'react-redux'

import style from './DashboardAccount.module.scss'

import { profileApi } from 'api'

import profileAvatar from 'i/profile_avatar.jpeg'

const DashboardAccount = ({ user }) => {
  const userLink = `https://www.talkpro.com/${user?.channel_name}`

  return (
    <>
      <div className={style.dashboardAccount}>
        <div className={style.dashboardAccountAvatarWrap}>
          <div className={style.dashboardAccountAvatar}>
            <input
              type="file"
              id="photo"
              name="photo"
              onChange={async (e) => {
                e.preventDefault()
                const formData = new FormData()
                formData.append('photo', e.target.files[0])
                profileApi.uploadPhoto(formData).then((res) => {
                  profileApi.setProInfo({ photo: res.result.fileUrl })
                  window.location.reload()
                })
              }}
            />

            {user?.photo ? (
              <img src={user?.photo} alt="" className={style.dashboardAccountAvatarIn} />
            ) : (
              <img
                src={profileAvatar}
                alt=""
                className={style.dashboardAccountAvatarIn}
              />
            )}
          </div>
        </div>
        <div className={style.dashboardAccountContent}>
          <div className={style.dashboardAccountName}>{user?.user?.name}</div>
          <div
            className={style.dashboardAccountLink}
            onClick={() => {
              navigator.clipboard.writeText(userLink)
            }}
          >
            talkpro.com/
            {user?.location?.state?.channelName || user?.channel_name || 'xxxxxxx'}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ auth: { error, isPro, user, calls } }) => ({
  authError: error,
  calls,
  user,
  isPro,
})

export default connect(mapStateToProps)(DashboardAccount)
